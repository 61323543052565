import { lazy } from "react";

// project imports
import Layout from "./../layouts";
import Loadable from "./../components/Loadable";

const Home = Loadable(lazy(() => import("../pages/home/home")));
const Stake = Loadable(lazy(() => import("../pages/stake")));
const Mint = Loadable(lazy(() => import("./../pages/mint/mint")));
const Terms = Loadable(lazy(() => import("./../pages/terms/terms")));
const Privacy = Loadable(lazy(() => import("./../pages/privacy/privacy")));
const Airdrop = Loadable(lazy(() => import("./../pages/airdrop/airdrop")));
const Charity = Loadable(lazy(() => import("./../pages/charity")));

const MainRoutes = {
  path: "/",
  element: <Layout />,
  children: [
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/mint",
      element: <Mint />,
    },
    {
      path: "/stake",
      element: <Stake />,
    },
    {
      path: "/terms",
      element: <Terms />,
    },
    {
      path: "/privacy",
      element: <Privacy />,
    },
    {
      path: "/airdrop",
      element: <Airdrop />,
    },
    {
      path: "/charity",
      element: <Charity />,
    },
  ],
};

export default MainRoutes;
