export const networkParams = {
  "0x1": {
    chainId: "0x1",
    rpcUrls: ["https://mainnet.infura.io/v3/"],
    chainName: "Ethereum Mainnet",
    nativeCurrency: { name: "ETHEREUM", decimals: 18, symbol: "ETH" },
    blockExplorerUrls: ["https://etherscan.io"],
    iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"]
  },
  "0x61": {
    chainId: "0x61",
    rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545/"],
    chainName: "Binance Smart Chain Testnet RPC",
    nativeCurrency: { name: "Binance", decimals: 18, symbol: "BNB" },
    blockExplorerUrls: ["https://testnet.bscscan.com"],
    iconUrls: ["https://harmonynews.one/wp-content/uploads/2019/11/slfdjs.png"]
  },
};
