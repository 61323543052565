import Header from './../components/Header';
import Footer from './../components/Footer';
import Wrapper from './Wrapper';
import { Outlet } from 'react-router-dom';
import Grid from "@mui/material/Grid";

const MainLayout = () => (
    <Grid >
        <Grid sx={{height:"65px"}}></Grid>
        <Grid item sx={{width:"100%", position:"relative"}} >
            <Header />
            <Wrapper>
                <Outlet />
            </Wrapper>
            <Footer />
        </Grid>
    </Grid>
);

export default MainLayout;
