import { InjectedConnector } from '@web3-react/injected-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import Config from '../config'
const injected = new InjectedConnector({
  supportedChainIds: [Config.netId]
})

const walletlink = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/`,
  appName: 'web3-react-demo'
})

export const connectors = {
  injected: injected,
  coinbaseWallet: walletlink
}
