import DogfaceABI from './abi.json'
import Dogface2ndABI from './dogface2nd.json'
import StakingABI from './stakingabi.json'
import dogface2stakingabi from './dogface2stakingabi.json'
import LpTokenABI from './lpabi.json'
import LpStakingABI from './lpstakingabi.json'
import {Alchemy, Network} from "alchemy-sdk";

const network = 'main'

const contractconfig =
  network === 'test'
    ? {
        netId: 97,
        DogfaceContract: {
          abi: DogfaceABI,
          address: '0xF22BAa14F2F02076AE66cf04d7d94cBFeA83eC63'
        },
        Dogface2ndContract: {
          abi: Dogface2ndABI,
          address: '0x677C7dd573659e3a257F8D3E94A8Feeb01E5fBE9'
        },
        StakingContract: {
          abi: StakingABI,
          address: '0xbABEbE99Ae1286C45cB59274Aa0cD1EeE55ea486'
        },
        Dogface2ndStakingContract: {
          abi: dogface2stakingabi,
          address: '0x0fCd8118BBc83f37185cE23184d919ea0886d2c5'
        },
        LPContract: {
          abi: LpTokenABI,
          address: '0x06946ccab4557f99a3044e4765f9444a4d9e5221'
        },
        LPStakingContract: {
          abi: LpStakingABI,
          address: '0x9bb653a95a2f00CaC516b57b657944E5b73AF857'
        }
      }
    : {
        netId: 1,
        DogfaceContract: {
          abi: DogfaceABI,
          address: '0xbfcb983a6c3e392cbddeca228854c51fbc29220a'
        },
        Dogface2ndContract: {
          abi: Dogface2ndABI,
          address: '0x480de54f42b17d459314f9D76cAa1A531B43c240'
        },
        StakingContract: {
          abi: StakingABI,
          address: '0x8297c2e7f6485d765d603d3b3f4eb245a156bdb6'
        },
        Dogface2ndStakingContract: {
          abi: dogface2stakingabi,
          address: '0x13a4c456adcb76548eef25be5afbdee018724938'
        },
        LPContract: {
          abi: LpTokenABI,
          address: '0x06946ccab4557f99a3044e4765f9444a4d9e5221'
        },
        LPStakingContract: {
          abi: LpStakingABI,
          address: '0x9bb653a95a2f00CaC516b57b657944E5b73AF857'
        }
      }

export const alchemy = new Alchemy({
    apiKey: '8bhDi0umni8F7FUesacV60Oa3bQMpuHK',
    //   network: 'bsc-testnet'
    network: Network.ETH_MAINNET
})
export default contractconfig
